import React from "react";
import styled from "styled-components";

export const InnerWrapper = styled.div`
  margin-bottom: 1.5rem;
  p {
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.xsmall};
    text-overflow: ellipsis;
    color: #111;
  }
`;

export const Header = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.regular};
  display: flex;
  align-items: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 7px;
    fill: #222 !important;
    display: inline;
  }
`;

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 5px 0;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`;
