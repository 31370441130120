import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CarCard from "@common/CarCard";
import DatePlaceForm from "@common/DatePlaceForm";
import CostsSummary from "@common/CostsSummary";
import styled from "styled-components";

const Heading = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.large};
  margin: 1rem 0;
  display: block;
  &:first-child {
    margin-top: 0;
  }
`;

const Wrapper = styled.section`
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  border-radius: 4px;
  max-width: 400px;
`;

const StandaloneCarCard = ({ state, showCosts, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            segments {
              name
              dayPrices
              deposit
              insurance
              cars {
                id
                name
                fuelType
                transmission
                engineEfficiency
                airConditioning
                fuelTypeFull
                seats
                doors
                productionYear
                engineVolume
                enginePower
                trunkCapacity
                range
                equipment
                description
              }
            }
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );

  const foundNode = data.allImageSharp.edges.find((element) => {
    // Match string after final slash
    return element.node.fluid.src.split("/").pop().includes(state.car.id);
  });
  const img = foundNode ? foundNode.node.fluid : null;

  return (
    <Wrapper>
      {showCosts ? (
        <>
          {/* <Heading>Koszty:</Heading> */}
          <CostsSummary state={state} />
        </>
      ) : null}
      <DatePlaceForm segment={state.segment} car={state.car} state={state} />
      {/* <Heading>Wybrany samochód:</Heading> */}
      <CarCard
        key={state.car.id}
        segment={state.segment}
        car={state.car}
        img={img}
        noBorder
        {...rest}
      />
    </Wrapper>
  );
};
export default StandaloneCarCard;
