import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 0.25rem;
`;
export const InnerWrapper = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  p {
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.small};
    text-overflow: ellipsis;
  }

  @media (min-width: 330px) {
    max-width: 330px;
  }
  @media (min-width: 400px) {
    max-width: 400px;
  }
  @media (min-width: 850px) {
    max-width: initial;
    padding: 20px 20px;
  }
`;

export const Value = styled(({ small, ...rest }) => <strong {...rest} />)`
  ${(props) =>
    props.small ? props.theme.font_size.small : props.theme.font_size.regular};
  ${(props) => (props.small ? "color: #666;" : "color: #111;")}
  grid-area: value;
  text-align: right;
  margin-right: 1rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 28px;
`;

export const Name = styled(({ small, ...rest }) => <p {...rest} />)`
  font-weight: ${(props) => (props.small ? "normal" : "bold")};
  ${(props) =>
    props.small ? props.theme.font_size.small : props.theme.font_size.small};
  ${(props) => {
    return props.small ? "color: #666;" : "color: #111;";
  }}
  grid-area: name;
  margin-left: 1rem;
  padding-right: 0.5rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 28px;
`;

export const Position = styled.div`
  display: grid;
  grid-template-areas: "name value";
  border-bottom: solid 1px #eaeaea;
  margin-left: -1rem;
  margin-right: -1rem;
  &:last-of-type {
    border-bottom: none;
  }
`;
