import React, { useState, useCallback, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import DateTimeContext from "@common/DateTimeContext";
import { Calendar } from "styled-icons/boxicons-regular";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MapPin } from "styled-icons/boxicons-solid";
import {
  Wrapper,
  InnerWrapper,
  TwoGrid,
  StyledSelect,
  Header,
  StyledTextField,
  Label,
} from "./style";
import { DateTime } from "luxon";

function getPlMonth(date) {
  let mon = "";
  if (date.month === 1) {
    mon = "sty";
  } else if (date.month === 2) {
    mon = "lut";
  } else if (date.month === 3) {
    mon = "mar";
  } else if (date.month === 4) {
    mon = "kwi";
  } else if (date.month === 5) {
    mon = "maj";
  } else if (date.month === 6) {
    mon = "cze";
  } else if (date.month === 7) {
    mon = "lip";
  } else if (date.month === 8) {
    mon = "sie";
  } else if (date.month === 9) {
    mon = "wrz";
  } else if (date.month === 10) {
    mon = "paź";
  } else if (date.month === 11) {
    mon = "lis";
  } else if (date.month === 12) {
    mon = "gru";
  }
  return mon;
}

function formatDate(date) {
  const datetime = DateTime.fromMillis(date);
  return `${datetime.toFormat("d")} ${getPlMonth(datetime)} ${datetime.toFormat(
    "yyyy H:mm"
  )}`;
}

function alignUp(unaligned, alignTo) {
  const aligned = Math.ceil(unaligned / alignTo) * alignTo;
  return aligned;
}

function alignDate(date) {
  return new Date(alignUp(date.getTime(), 1000 * 60 * 15));
}

const CalendarInput = ({ ...props }) => {
  return <TextField {...props} fullWidth InputLabelProps={{ shrink: true }} />;
};

const CalendarInputOdbior = ({ ...props }) => {
  return <CalendarInput {...props} />;
};

const CalendarInputZwrot = ({ ...props }) => {
  return <CalendarInput {...props} />;
};

const DatePlaceForm = ({ car, segment, state, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            offices {
              name
              workingHoursMonFri {
                start
                end
              }
              workingHoursSaturday {
                start
                end
              }
              deliveryFee
              returnFee
              deliveryFeeOutsideWorkingHours
              returnFeeOutsideWorkingHours
            }
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );
  const [odbior, setOdbior] = useState(
    state ? state.odbior : data.site.siteMetadata.offices[0].name
  );
  const [zwrot, setZwrot] = useState(
    state ? state.zwrot : data.site.siteMetadata.offices[0].name
  );
  const [odbiorAddress, setOdbiorAddress] = useState(
    state ? state.odbiorAddress : ""
  );
  const [zwrotAddress, setZwrotAddress] = useState(
    state ? state.zwrotAddress : ""
  );
  const { samePlace } = state;

  const odbiorDateFormat = formatDate(state.odbiorDate);
  const zwrotDateFormat = formatDate(state.zwrotDate);

  return (
    <InnerWrapper>
      <TwoGrid>
        <div>
          <Header>
            <MapPin /> Miejsce odbioru
          </Header>
          <p>{odbior}</p>
        </div>
        <div>
          <Header>
            <MapPin /> Miejsce zwrotu
          </Header>
          <p>{zwrot}</p>
        </div>
      </TwoGrid>

      {odbior.includes("adres") ? (
        <>
          <Header>
            {samePlace ? "Adres odbioru i zwrotu" : "Adres odbioru"}
          </Header>
          <p>{odbiorAddress}</p>
        </>
      ) : null}
      {zwrot.includes("adres") && !samePlace ? (
        <>
          <Header>Adres zwrotu</Header>
          <p>{zwrotAddress}</p>
        </>
      ) : null}

      <TwoGrid>
        <div>
          <Header>
            <Calendar /> Data odbioru
          </Header>
          <p>{odbiorDateFormat}</p>
        </div>
        <div>
          <Header>
            <Calendar /> Data zwrotu
          </Header>
          <p>{zwrotDateFormat}</p>
        </div>
      </TwoGrid>
    </InnerWrapper>
  );
};

export default DatePlaceForm;
