import React, { useState, useCallback, useEffect } from "react";
import { Wrapper, Position, Name, Value } from "./style";
import { calculateRental } from "./../../../calc";

const CostsSummary = ({ state, ...props }) => {
  const calc = calculateRental(
    state.odbiorDate,
    state.zwrotDate,
    state.segment,
    state.car,
    state.odbiorOffice,
    state.zwrotOffice,
    state.options,
    state.insurance
  );
  return (
    <Wrapper>
      <Position>
        <Name>
          {calc.numDays} {calc.numDays === 1 ? "dzień" : "dni"} x{" "}
          {calc.dayPrice} zł/doba
        </Name>
        <Value>{calc.periodPrice} zł</Value>
      </Position>
      {calc.insuranceTotalCost ? (
        <Position>
          <Name>Ubezpieczenie</Name>
          <Value>{calc.insuranceTotalCost} zł</Value>
        </Position>
      ) : null}
      {calc.depositCost ? (
        <Position>
          <Name>Kaucja</Name>
          <Value>{calc.depositCost} zł</Value>
        </Position>
      ) : null}

      {calc.optionsPositions.length > 0 ? (
        <>
          <Position>
            <Name>Udogodnienia:</Name>
            <Value>{calc.optionsSum} zł</Value>
          </Position>
          {calc.optionsPositions.map((pos) => (
            <Position key={pos.name}>
              <Name small>{pos.name}</Name>
              <Value small>{pos.value}</Value>
            </Position>
          ))}
        </>
      ) : null}

      {calc.feesPositions.length > 0 ? (
        <>
          <Position>
            <Name>Opłaty dodatkowe:</Name>
            <Value>{calc.feesSum} zł</Value>
          </Position>
          {calc.feesPositions.map((pos) => (
            <Position key={pos.name}>
              <Name small>{pos.name}</Name>
              <Value small>{pos.value}</Value>
            </Position>
          ))}
        </>
      ) : null}

      <Position>
        <Name>Razem</Name>
        <Value>{calc.sum} zł</Value>
      </Position>
    </Wrapper>
  );
};

export default CostsSummary;
