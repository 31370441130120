import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import StandaloneCarCard from "@common/StandaloneCarCard";
import { navigate, graphql } from "gatsby";

import { Breadcrumbs } from "@common/Breadcrumbs";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

import Footer from "@sections/Footer";
import { Container } from "@components/global";

const NextButton = styled(({ className, ...rest }) => {
  return (
    <div className={className}>
      <Button {...rest} />
    </div>
  );
})`
  text-align: center;
  margin-top: 1rem;
`;

export const Header = styled.h1`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.larger};
  margin: 1rem 0;
`;

export const Options = styled.div`
  grid-area: options;
  margin-bottom: 2.5rem;
`;

export const CarCard = styled.div`
  grid-area: carCard;
`;

export const OptionCheckboxLabel = styled(FormControlLabel)`
  display: block;
`;

export const OptionCheckbox = styled(Checkbox)``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "options"
    "carCard";
  @media (min-width: 960px) {
    grid-template-areas: "options carCard";
    align-items: flex-start;
    justify-items: center;
    grid-gap: 2rem;
  }
  margin-bottom: 3rem;
`;

const ReservationOptionsPage = ({ data, location }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    location.state && location.state.options ? [...location.state.options] : []
  );
  useEffect(() => {
    if (
      (typeof window !== "undefined" && !location.state) ||
      !location.state ||
      !location.state.car
    ) {
      window.location.href = "/";
    }
  });
  if (
    (typeof window !== "undefined" && !location.state) ||
    !location.state ||
    !location.state.car
  ) {
    return null;
  }
  return (
    <Layout>
      <TopHeader />
      <Container>
        <Breadcrumbs
          miniElement={{
            href: `/rezerwacja/ubezpieczenie`,
            label: "Ubezpieczenie",
            state: location.state,
          }}
          path={[
            {
              href: `/`,
              label: "Rezerwacja",
              state: location.state,
            },
            {
              href: `/rezerwacja/samochod`,
              label: "Wybór samochodu",
              state: location.state,
            },
            {
              href: `/rezerwacja/ubezpieczenie`,
              label: "Ubezpieczenie",
              state: location.state,
            },
            {
              href: `/rezerwacja/udogodnienia`,
              label: "Udogodnienia",
              state: location.state,
              current: true,
            },
            ,
            {
              href: `/rezerwacja/podsumowanie`,
              label: "Podsumowanie",
              state: location.state,
              disabled: true,
            },
          ]}
        />
        <Wrapper>
          <Options>
            <Header>Krok 4 - wybierz udogodnienia</Header>
            {data.site.siteMetadata.options.map((option) => (
              <OptionCheckboxLabel
                key={option.name}
                control={
                  <OptionCheckbox
                    checked={
                      selectedOptions.find((o) => o.name === option.name) !==
                      undefined
                    }
                    onChange={(ev) => {
                      const checked = ev.target.checked;
                      let newSet = [...selectedOptions];
                      if (checked) {
                        newSet.push(option);
                        setSelectedOptions(newSet);
                      } else {
                        newSet = newSet.filter((o) => o.name !== option.name);
                        setSelectedOptions(newSet);
                      }
                    }}
                    color="primary"
                  />
                }
                label={
                  option.multiplyByDays
                    ? `${option.name} - ${option.price} zł za dobę`
                    : `${option.name} - ${option.price} zł`
                }
              />
            ))}
            <NextButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                const state = {
                  ...location.state,
                  options: selectedOptions,
                };
                navigate("/rezerwacja/podsumowanie", {
                  state,
                });
              }}
            >
              Dalej
            </NextButton>
          </Options>
          <CarCard>
            <StandaloneCarCard
              hideButtons
              hidePrices
              noHoverEffect
              showCosts
              state={{
                ...location.state,
                options: selectedOptions,
              }}
            />
          </CarCard>
        </Wrapper>
      </Container>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ReservationOptionsPageQuery {
    site {
      siteMetadata {
        options {
          name
          price
          multiplyByDays
        }
        offices {
          name
          workingHoursMonFri {
            start
            end
          }
          workingHoursSaturday {
            start
            end
          }
          deliveryFee
          returnFee
          deliveryFeeOutsideWorkingHours
          returnFeeOutsideWorkingHours
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default ReservationOptionsPage;
